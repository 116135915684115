import React from 'react';
import Footer from '../Footer';
import Div from '../../../components/Div';
import { Col, Container, Row } from '../../../components/TheGrid';

export default ({
  children,
}) => (
  <React.Fragment>
    <Container>
      <Row padding="0">
        <Col xs={12}>
          <Div>
            {children}
          </Div>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row padding="0">
        <Col xs={12}>
          <Div>
            <Footer />
          </Div>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);
