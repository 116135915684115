import React from "react";
import styled from "styled-components/macro";
import Div from "./Div";
import { LinkButton, Paragraph } from "./typography";
import ImageSimple from "./Image/ImageSimple";
import { ScreenSizeDetector } from "../lib/helpers";

const TileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 1599px) {
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const TileColumn = styled.div`
  display: flex;
  flex-direction: ${({ inner }) => (inner ? "row" : "column")};
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: ${({ inner }) => (inner ? "100%" : "50%")};

  @media (max-width: 1599px) {
    gap: 15px;
  }

  @media (max-width: 1199px) {
    flex-direction: ${({ inner }) => (inner ? "column" : "column")};
  }

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: ${({ inner, reverse }) =>
      inner ? "row" : reverse ? "column-reverse" : "column"};
  }

  @media (max-width: 500px) {
    flex-direction: ${({ inner, reverse }) =>
      inner ? "column" : reverse ? "column-reverse" : "column"};
  }
`;

const TileRatio = styled(Div)`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  width: ${({ big }) => (big ? "100%" : "50%")};
  padding-top: ${({ big }) => (big ? "45.9475%" : "31.0825%")};

  @media (max-width: 1599px) {
    padding-top: ${({ big }) => (big ? "52.9475%" : "38.0825%")};
  }

  @media (max-width: 1199px) {
    width: ${({ big }) => (big ? "100%" : "100%")};
    padding-top: ${({ big }) => (big ? "57.9475%" : "57.9475%")};
  }

  @media (max-width: 991px) {
    padding-top: ${({ big }) => (big ? "45.9475%" : "31.0825%")};
  }

  @media (max-width: 767px) {
    padding-top: ${({ big }) => (big ? "49.9475%" : "37.0825%")};
  }

  @media (max-width: 575px) {
    padding-top: ${({ big }) => (big ? "51.9475%" : "45.0825%")};
  }

  @media (max-width: 500px) {
    padding-top: ${({ big }) => (big ? "57.9475%" : "57.9475%")};
  }

  @media (max-width: 440px) {
    padding-top: ${({ big }) => (big ? "66.9475%" : "66.9475%")};
  }

  @media (max-width: 400px) {
    padding-top: ${({ big }) => (big ? "70.9475%" : "70.9475%")};
  }
`;
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  padding: 15px;
  font-size: 20px;
  line-height: 30px;

  @media (max-width: 1599px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  @media (max-width: 400px) {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
const Tile = ({ children, big, reverse }) => {
  return (
    <TileRatio big={big} reverse={reverse}>
      <Content>{children}</Content>
    </TileRatio>
  );
};

const Title = styled(Paragraph)`
  font-family: "Inter", sans-serif;
  font-size: ${({ subtitle }) => (subtitle ? "20px" : "38px")};
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin-top: ${({ subtitle }) => (subtitle ? "10px" : "")};
  margin-bottom: ${({ subtitle }) => (subtitle ? "10px" : "5px")};

  @media (max-width: 1599px) {
    font-size: ${({ subtitle }) => (subtitle ? "16px" : "30px")};
  }

  @media (max-width: 767px) {
    font-size: ${({ subtitle }) => (subtitle ? "14px" : "24px")};
  }
`;

const LinkButtonTile = styled(LinkButton)`
  position: absolute;
  bottom: 15px;
  left: 15px;

  @media (max-width: 1599px) {
    padding: 10px 15px;
  }

  @media (max-width: 440px) {
    bottom: 5px;
    left: 5px;
  }

  @media (max-width: 500px) {
    position: relative;
    bottom: 0;
    left: 0;
    top: 10px;
  }

  @media (max-width: 340px) {
    padding: 5px 10px;
    border-radius: 10px;
  }
`;

const ShowHide = styled.span`
  display: ${({ hide }) => (hide ? "inline" : "none")};

  @media (max-width: ${({ breakpoint }) => breakpoint}px) {
    display: ${({ hide }) => (hide ? "none" : "inline")};
  }
`;

const Text = styled.div`
  @media (max-width: 575px) {
    & > svg {
      display: none;
    }
  }

  @media (max-width: 440px) {
    width: 200px;
  }
`;

const TileImage = styled(ImageSimple)`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;

  ${({ position }) => {
    if (position == "bottomRight") {
      return `
        bottom: 0;
        right: 0;
      `;
    }
    return `
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    `;
  }}
`;

const HomeTiles = () => {
  return (
    <TileWrapper>
      <TileColumn>
        <Tile big>
          <Title>Fadølsanlæg</Title>
          <Title subtitle>Altid ledige anlæg</Title>
          <Text>
            Levering & afhentning alle ugens dage
            <ShowHide hide breakpoint={440}>
              <br />
            </ShowHide>
            <ShowHide breakpoint={440}>. </ShowHide>
            Kan lejes med fadølsbar
            <ShowHide hide breakpoint={440}>
              <br />
            </ShowHide>
            <ShowHide breakpoint={440}>. </ShowHide>
            Ølglas kan tilkøbes<ShowHide breakpoint={440}>. </ShowHide>
          </Text>
          <LinkButtonTile arrow to="/fadoelsanlaeg">
            Fadølsanlæg
          </LinkButtonTile>
          <ScreenSizeDetector
            imageWidth={[
              [575, 578],
              [767, 554],
              [991, 720],
              [1199, 576],
              [1599, 632],
              740,
            ]}
          >
            <TileImage name="home.tile-fadoelsanlaeg-v3.png" />
          </ScreenSizeDetector>
        </Tile>
        <TileColumn inner>
          <Tile>
            <Title>Fadøl</Title>
            <Text>
              Mere end 100 slags
              <ShowHide hide breakpoint={440}>
                <br />
              </ShowHide>
              <ShowHide breakpoint={440}>. </ShowHide>
              Ubrudte fustager
              <ShowHide hide breakpoint={440}>
                <br />
              </ShowHide>
              <ShowHide breakpoint={440}> </ShowHide>
              tages retur<ShowHide breakpoint={440}>. </ShowHide>
            </Text>
            <LinkButtonTile arrow to="/fadoel">
              Fadøl
            </LinkButtonTile>
            <ScreenSizeDetector
              imageWidth={[
                [500, 453],
                [575, 354],
                [767, 291],
                [991, 353],
                [1199, 458],
                [1599, 322],
                355,
              ]}
            >
              <TileImage name="home.tile-fadoel-v1.png" />
            </ScreenSizeDetector>
          </Tile>
          <Tile>
            <Title>Kulsyre</Title>
            <Text>
              Kulsyre til alle formål
              <br />
              Lave priser og engros
            </Text>
            <LinkButtonTile arrow to="/kulsyre">
              Kulsyre
            </LinkButtonTile>
            <ScreenSizeDetector
              imageWidth={[
                [500, 453],
                [575, 354],
                [767, 291],
                [991, 353],
                [1199, 458],
                [1599, 322],
                355,
              ]}
            >
              <TileImage name="home.tile-kulsyre-v1.png" />
            </ScreenSizeDetector>
          </Tile>
        </TileColumn>
      </TileColumn>
      <TileColumn reverse>
        <TileColumn inner>
          <Tile>
            <Title>Kildevand</Title>
            <Text>
              Vand i alle kvaliteter
              <br />
              Engros i paller
              <br />
              Vand med logo
            </Text>
            <LinkButtonTile arrow to="/kildevand">
              Kildevand
            </LinkButtonTile>
            <ScreenSizeDetector
              imageWidth={[
                [500, 453],
                [575, 354],
                [767, 291],
                [991, 353],
                [1199, 458],
                [1599, 322],
                355,
              ]}
            >
              <TileImage name="home.tile-kildevand-v1.png" />
            </ScreenSizeDetector>
          </Tile>
          <Tile>
            <Title>Sodavand</Title>
            <Text>
              Kæmpe udvalg
              <br />
              Alle størrelser
              <br />
              Forfriskende nemt
            </Text>
            <LinkButtonTile arrow to="/sodavand">
              Sodavand
            </LinkButtonTile>
            <ScreenSizeDetector
              imageWidth={[
                [500, 453],
                [575, 354],
                [767, 291],
                [991, 353],
                [1199, 458],
                [1599, 322],
                355,
              ]}
            >
              <TileImage name="home.tile-sodavand-v1.png" />
            </ScreenSizeDetector>
          </Tile>
        </TileColumn>
        <Tile big>
          <Title>Din leverandør</Title>
          <Title subtitle>
            Vand, sodavand, saft, øl
            <ShowHide hide breakpoint={1199}>
              {" "}
              og andre drikkevarer
            </ShowHide>
          </Title>
          <Text>
            Dag til dag levering - Gratis fra 5 kasser
            <ShowHide hide breakpoint={440}>
              <br />
            </ShowHide>
            <ShowHide breakpoint={440}>. </ShowHide>
            Levering helt ind i skabet
            <ShowHide hide breakpoint={440}>
              <br />
            </ShowHide>
            <ShowHide breakpoint={440}>. </ShowHide>
            Vi tager det tomme retur
            <ShowHide hide breakpoint={440}>
              <br />
            </ShowHide>
            <ShowHide breakpoint={440}>. </ShowHide>
          </Text>
          <LinkButtonTile arrow to="/leverandoer-sodavand-kildevand-oel">
            Din leverandør
          </LinkButtonTile>
          <ScreenSizeDetector
            imageWidth={[
              [575, 578],
              [767, 571],
              [991, 742],
              [1199, 594],
              [1599, 650],
              762,
            ]}
          >
            <TileImage
              name="home.tile-leverandoer-v1.png"
              position="bottomRight"
            />
          </ScreenSizeDetector>
        </Tile>
      </TileColumn>
    </TileWrapper>
  );
};

export default HomeTiles;
