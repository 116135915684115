import React from 'react';
import styled from 'styled-components/macro';
import {
  Container, Col, Row,
} from '../components/grid';
import {
  LinkStyled, Heading2, Paragraph, LinkButton,
} from '../components/typography';
import Datoer from '../components/Datoer';
import FullWidthLayout from '../layout/DefaultLayout/Layouts/FullWidthLayout';
import HomeTiles from '../components/HomeTiles';

const CustomContainer = styled(Container)`
  margin-bottom: 30px;

  @media (max-width: 1599px) {
    margin-bottom: 15px;
  }
`;

export default () => (
  <FullWidthLayout>
    <HomeTiles />
    <CustomContainer white>
      <Row>
        <Col xs={12} paddingBottom="0px">
          <Paragraph bold fontSize="16px">
            Din leverandør af drikkevarer
          </Paragraph>
        </Col>
        <Col xs={12} lg={4} paddingTop="0px">
          <Paragraph>
            I <Datoer type="experience" /> år har Skafte Øl &amp; Vand leveret fadøl, vand, øl, sodavand, juice og saft til hele Storkøbenhavn og omegn.
            Vi sælger til alle: Privat, erhverv, samt offentlige virksomheder, også EAN.
          </Paragraph>
        </Col>
        <Col xs={12} lg={4} paddingTop="0px">
          <Paragraph>
            Vi har et stort sortiment af drikkevarer til enhver lejlighed. Om I ønsker friske kildevand i kantinen eller kold fadøl til familiefesten så har vi drikkevarerne til at slukke jeres tørst.
          </Paragraph>
        </Col>
        <Col xs={12} lg={4} paddingTop="0px" textCenter>
          <LinkButton justifyCenter alignCenter expandHeight cart to="/bestil/flasker-og-daaser/kildevand" title="Gå til onlinebestilling af øl &amp; vand">
            Bestil her
          </LinkButton>
        </Col>
      </Row>
    </CustomContainer>
    <Container white>
      <Row>
        <Col xs={12} md={6}>
          <Heading2 marginBottom="20px">Leje af fadølsanlæg og køb fadøl til fest</Heading2>
          <Paragraph>
            Vi er eksperter i fadølsanlæg og vi leverer og opstiller fadølsanlægget for dig. Efter festen henter vi det hele igen, ubrudte fustager tages retur. Der er også mulighed for levering i weekenden. Eller du kan afhente selv gratis på vores lager i Brøndby.
          </Paragraph>
          <Paragraph>
            Vi leverer til private såvel som firmaer, skoler, selskabslokaler m.m. Uforbindende tilbud gives gerne - kontakt os på 43 27 00 00 eller <LinkStyled anchor to="mailto:peter@skafte.dk">peter@skafte.dk</LinkStyled>.
            Læs mere om <LinkStyled title="Fadøl" to="/fadoel">fadøl</LinkStyled>, <LinkStyled title="Fadøl" to="/fadoelsanlaeg">fadølsanlæg</LinkStyled> eller <LinkStyled title="Onlinebestilling af fadøl, kan også bruges til prisberegning" to="/bestil/fadoel-og-anlaeg/fadoel">gå til nembestilling af fadøl og anlæg.</LinkStyled>
          </Paragraph>
          <Paragraph>
            Vi forhandler et meget stort sortiment af fadøl og har mere end 100 forskelllige varianter.
            Se mere på siderne om <LinkStyled title="Damsle fadøl" to="/fadoel/dansk-fadoel">danske fadøl</LinkStyled> og <LinkStyled title="Udenlandske fadøl" to="/fadoel/udenlandske-oel-paa-fad">udenlandske fadøl</LinkStyled>.
          </Paragraph>
          <Paragraph marginTop="10px">
            <LinkButton to="/bestil/fadoel-og-anlaeg/fadoel" title="Gå til onlinebestilling af fadøl &amp; anlæg" arrow>
              Bestil fadøl &amp; anlæg online
            </LinkButton>
          </Paragraph>
        </Col>
        <Col xs={12} md={6}>
          <Heading2 marginBottom="20px">Øl og sodavand sælges og leveres</Heading2>
          <Paragraph>
            Køber du af os, ruller vi kasserne med øl og sodavand på plads - sætter dem ind i skabe, på lageret eller hvor du ellers kunne ønske det. Du undgår helt selv at håndtere kasserne, før du skal bruge dem.
          </Paragraph>
          <Paragraph>
            Vi sætter de nye kasser pænt på plads og rykker de gamle frem, så du får brugt drikkevarerne i den rigtige rækkefølge. Vi tager selvfølgelig de tomme genbrugsflasker med retur. Læs mere om <LinkStyled to="/leverandoer-sodavand-kildevand-oel" title="Levering af øl &amp; sodavand">levering af øl &amp; sodavand</LinkStyled>.
          </Paragraph>
          <Paragraph>
            Vi udbringer alle kendte mærkevarer i øl, sodavand og kildevand samt <LinkStyled to="/soebogaard" title="Søbogaard">økologiske Søbogaard safter</LinkStyled> og <LinkStyled to="/sodavand/frem-sodavand" title="Frem Sodavand">billige sodavand fra Frem</LinkStyled>. Få den gode service - køb drikkevarer hos os.
          </Paragraph>
          <Paragraph marginTop="10px">
            <LinkButton to="/bestil/flasker-og-daaser/kildevand" title="Gå til onlinebestilling af øl &amp; vand" arrow>
              Bestil øl &amp; vand online
            </LinkButton>
          </Paragraph>
        </Col>
      </Row>
    </Container>
  </FullWidthLayout>
);
